import React from 'react';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import { WEBP_SVG_TYPE } from 'Constants/images';
import { fileNames } from 'Constants/imageUrls';
import classNames from 'classnames';
import css from './DiscountBadge.scss';
import PropTypes from 'prop-types';

const DiscountBadge = ({ discountText, size, className }) => {
    const getIconImage = () => {
        let fileName = fileNames.DISCOUNT_ICON_SMALL;

        switch (size) {
            case 'xl':
                fileName = fileNames.DISCOUNT_ICON_EXTRA_LARGE;
                return fileName;

            case 'lg':
                fileName = fileNames.DISCOUNT_ICON_LARGE;
                return fileName;
            case 'md':
                fileName = fileNames.DISCOUNT_ICON_MEDIUM;
                return fileName;
            case 'sm':
                return fileName;
            default:
                return fileName;
        }
    };

    return (
        <div className={ classNames(css.discountBadge, `${css[size]}`, className) } data-aut-id="iconDiscount" >
            <ImageWrapper
                fileName={ `https://statics.olx.co.id/${getIconImage()}` }
                types={ WEBP_SVG_TYPE }
                addSVG
                supportForDifferentSize
                className={ css.discountIcon }
            />
            {discountText}
        </div>
    );
};

DiscountBadge.propTypes = {
    discountText: PropTypes.string,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
    className: PropTypes.string
};

DiscountBadge.defaultProps = {
    size: 'md'
};

export default DiscountBadge;
