import React from 'react';
import PropTypes from 'prop-types';
import css from './ItemImage.scss';
import { css as uicss } from 'panamera-react-ui';
import ImageLoader from './ImageLoader';
import ReactObserver from 'Components/ReactObserver/ReactObserver';
import { LAZYLOAD_OFFSET_VERTICAL } from './constants';
import classNames from 'classnames';
import { QUALITY_IMAGE_60 } from 'Constants/images';
import { fileNames } from 'Constants/imageUrls';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import { isFeatured, isSpotLight } from 'Helpers/adActionStatus';
import { IS_DESKTOP } from 'Constants/device.APP_TARGET';

const { icons } = uicss;
const FIRST_IMAGE_SHOWN_DESKTOP = 9;
const FIRST_IMAGE_SHOWN_MOBILE = 6;

export class ItemImage extends React.PureComponent {
    static propTypes = {
        chosenOption: PropTypes.number,
        image: PropTypes.shape({
            url: PropTypes.string,
            width: PropTypes.number,
            height: PropTypes.number
        }).isRequired,
        alt: PropTypes.string,
        defaultSize: PropTypes.string,
        friendsInCommon: PropTypes.bool,
        visualizationType: PropTypes.string.isRequired,
        listingType: PropTypes.string,
        'data-aut-id': PropTypes.string,
        height: PropTypes.number,
        width: PropTypes.number,
        isCarImage: PropTypes.bool,
        frmMyAdsV2: PropTypes.bool,
        videoAvailableBadge: PropTypes.element,
        monetizationInfo: PropTypes.object,
        topBadgeContent: PropTypes.element,
        showDiscountPercentIcon: PropTypes.bool,
        bottomBadgeContent: PropTypes.element,
        className: PropTypes.string
    };

    static defaultProps = {
        chosenOption: null,
        defaultSize: 's=300x0',
        friendsInCommon: false,
        alt: '',
        listingType: 'items',
        'data-aut-id': 'itemImage',
        isCarImage: false,
        frmMyAdsV2: false,
        monetizationInfo: {},
        showDiscountPercentIcon: false,
        className: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            imageStatus: false,
            src: ''
        };
    }

    handleImageLoaded = () => {
        this.setState({ imageStatus: true });
    };

    getSizes = () => {
        const { visualizationType, listingType } = this.props;

        if (visualizationType === 'grid') {
            return '(min-width: 1280px) 15vw, (min-width: 1024px) 20vw, (min-width: 960px) 25vw, (min-width: 540px) 25vw, (min-width: 320px) 30vw, 35vw';
        }
        else if (visualizationType === 'list') {
            return '(min-width: 1280px) 20vw, (min-width: 1024px) 20vw, (min-width: 960px) 25vw, (min-width: 540px) 25vw, (min-width: 320px) 25vw, 35vw';
        }
        else if (listingType === 'reProjects') {
            return null;
        }
        return '(min-width: 1280px) 100vw, (min-width: 1024px) 20vw, (min-width: 960px) 25vw, (min-width: 540px) 25vw, (min-width: 320px) 30vw, 35vw';
    };

    isPreloadImage = () => {
        const { chosenOption } = this.props;
        const firstImageShown = IS_DESKTOP ? FIRST_IMAGE_SHOWN_DESKTOP : FIRST_IMAGE_SHOWN_MOBILE;

        if (chosenOption === null) {
            return false;
        }

        return chosenOption < firstImageShown;
    };

    getMonetRelevantTag = () => {
        const { frmMyAdsV2, monetizationInfo } = this.props;

        if (frmMyAdsV2) {
            if (isSpotLight(monetizationInfo)) {
                return (<ImageWrapper
                    fileName={ `https://statics.olx.co.id/${fileNames.SPOTLIGHT}` }
                    className={ css.highlightImage }
                    alt="spotlight"
                />);
            }
            else if (isFeatured(monetizationInfo)) {
                return (<ImageWrapper
                    fileName={ `https://statics.olx.co.id/${fileNames.HIGHLIGHT}` }
                    className={ css.highlightImage }
                    alt="highlight"
                />);
            }
        }
        return null;
    }

    /* eslint-disable max-params */
    itemImageComponent = (imageContent, friendsClass, visualizationType, dataAutid, frmMyAdsV2, monetizationInfo, videoAvailableBadge, topBadgeContent, showDiscountPercentIcon, className) => ({ inView, ref }) => (
        <figure ref={ ref } className={ classNames(css.itemImage, css[visualizationType], friendsClass, { [css.myAdsClass]: frmMyAdsV2 }, className) } data-aut-id={ dataAutid }>
            { this.getMonetRelevantTag() }
            {/* {
                showDiscountPercentIcon
                && <ImageWrapper
                    fileName={ `https://statics.olx.co.id${fileNames.FLAG_RED_DISCOUNT}` }
                    // types={ WEBP_PNG_TYPE }
                    supportForDifferentSize
                    className={ css.redDiscountIcon }
                    data-aut-id="iconDiscount"
                />

            } */}
            {topBadgeContent
                 && <div className={ css.topBadgeContainer }>
                     {topBadgeContent}
                 </div>
            }
            <ImageLoader loaded={ this.isPreloadImage() || this.state.imageStatus }>
                { (this.isPreloadImage() || inView || this.state.imageStatus) ? imageContent : null }
            </ImageLoader>
            <noscript>{ imageContent }</noscript>
            { videoAvailableBadge }
            {this.props.bottomBadgeContent && <div className={ classNames(css.bottomBadgeContainer) }>
                {this.props.bottomBadgeContent}
            </div>}
        </figure>
    );

    render() {
        const { image, defaultSize, friendsInCommon, alt, visualizationType, 'data-aut-id': dataAutid, listingType, height, width, isCarImage, frmMyAdsV2, monetizationInfo, videoAvailableBadge, topBadgeContent, showDiscountPercentIcon, className } = this.props;
        const isAkamaizedImage = image.url && (image.url.includes('.akamaized.net') || image.url.includes('.cloudfront.net') || image.url.includes('apollo.olx.co.id') || image.url.includes('apollo-stg.olx.co.id'));
        const friendsClass = friendsInCommon ? `${icons['icon-Friends']} ${icons.panameraIcons}` : '';
        let srcSet;
        let src;
        let imageClassName;
        const sizes = this.getSizes();

        if (isAkamaizedImage) {
            src = `${image.url};${defaultSize}`;
            srcSet = `${ image.url };s=100x200;q=${ QUALITY_IMAGE_60 } 100w,
                    ${ image.url };s=200x400;q=${ QUALITY_IMAGE_60 } 200w,
                    ${ image.url };s=300x600;q=${ QUALITY_IMAGE_60 } 300w,
                    ${ image.url };s=400x800;q=${ QUALITY_IMAGE_60 } 400w,
                    ${ image.url };s=600x1200;q=${ QUALITY_IMAGE_60 } 600w`;
        }
        else {
            src = image.url;
        }

        const widthtImage = image.width > image.height;

        if (visualizationType === 'list') {
            imageClassName = widthtImage ? css.listWidth : css.listHeight;
        }
        else {
            imageClassName = widthtImage ? css.width : css.height;
        }

        const imageContent = (<img
            src={ src }
            alt={ alt }
            sizes={ sizes }
            srcSet={ srcSet }
            className={ classNames(imageClassName, { [css.projectImage]: listingType === 'reProjects' }, { [css.carImage]: isCarImage }) }
            onLoad={ this.handleImageLoaded }
            height={ height }
            width={ width }
            fetchPriority={ this.isPreloadImage() ? 'high' : 'auto' }
        />);

        return (
            <ReactObserver rootMargin={ `${LAZYLOAD_OFFSET_VERTICAL}px` }>
                { this.itemImageComponent(imageContent, friendsClass, visualizationType, dataAutid, frmMyAdsV2, monetizationInfo, videoAvailableBadge, topBadgeContent, showDiscountPercentIcon, className) }
            </ReactObserver>
        );
    }
}

export default ItemImage;
