import { adStatus } from 'Constants/adStatus';
import { isEmpty } from 'Helpers/objects';
import { canBeFeatured } from 'Helpers/limitAdStatus';
import { __FEATURE_TYPE_SPOTLIGHT } from 'Constants/monetizers';

const PENDING_STATUS = [adStatus.NEW, adStatus.BLOCKED, adStatus.PENDING, adStatus.UNCONFIRMED, adStatus.MODERATED];

export const isRejected = status => status && status.status === adStatus.MODERATED;

export const isHardRejected = status => isRejected(status) && !status.allow_edit;

export const isSoftRejected = status => isRejected(status) && status.allow_edit;

export const isDisabled = status => status && status.status === adStatus.DISABLED;

export const isSold = status => status && status.status === adStatus.SOLD;

export const isActive = status => status && status.status === adStatus.ACTIVE;

export const isOutdated = status => status && status.status === adStatus.OUTDATED;

export const isLimited = status => status && status.status === adStatus.LIMITED;

export const isPending = status => status.status && PENDING_STATUS.includes(status.status);

export const isBlocked = status => status && status.status === adStatus.BLOCKED;

export const isRemovedByAdmin = status => status && status.status === adStatus.REMOVED_BY_ADMIN;

export const isRemovedByModerator = status => status && status.status === adStatus.REMOVED_BY_MODERATOR;

export const isRemovedByUser = status => status && status.status === adStatus.REMOVED_BY_USER;

export const isAllowDeactivate = status => status && status.allow_deactivate;

export const isFeatured = monetizationInfo => {
    return !!monetizationInfo
    && !!monetizationInfo.currentProduct;
};

export const isSpotLight = monetizationInfo => {
    return !!monetizationInfo
    && monetizationInfo.monetInfoType
    && monetizationInfo.monetInfoType === __FEATURE_TYPE_SPOTLIGHT;
};

export const isAdAutoBoosted = monetizationInfo => {
    return !!monetizationInfo
    && !!monetizationInfo.autoBoostAtAdApplied;
};

export const limitedItemCanBePosted = ({ monetizationInfo, status }) => {
    return isLimited(status) && !isEmpty(monetizationInfo) && !isEmpty(monetizationInfo.limits);
};

export const itemCanBeFeatured = (item, loggedUser) => {
    const { monetizationInfo } = item;

    if (!monetizationInfo) {
        return false;
    }

    const { currentProduct, currentPackage: oldCurrentProduct } = monetizationInfo;
    const currentPackage = currentProduct || oldCurrentProduct || false;

    return canBeFeatured(loggedUser, item) && !currentPackage;
};

/**
 * My Ads :: Actions
 */
export const canEdit = status => {
    return status.allow_edit && !isOutdated(status) && !isSoftRejected(status) && !isDisabled(status);
};
export const canMarkAsSold = status => {
    return ![adStatus.SOLD,
        adStatus.LIMITED,
        adStatus.MODERATED,
        adStatus.REMOVED_BY_USER,
        ...PENDING_STATUS].includes(status.status);
};
export const canRepublish = status => {
    return status.allow_edit && isOutdated(status) || isDisabled(status);
};

export const canDeactivate = (status, monetizationInfo) => {
    return isActive(status)
        && !isFeatured(monetizationInfo)
        && isAllowDeactivate(status);
};

export const canRemove = status => {
    return !isSold(status);
};

export const canDownloadLeads = isLeadAvailable => {
    return isLeadAvailable;
};

